import styled from '@emotion/styled'
import CardMedia from '@mui/material/CardMedia'
interface LogoCardMediaInterface {
  component: any
  src: any
  height: any
}

export const LogoCardMedia = styled(CardMedia)<LogoCardMediaInterface>`
  object-fit: cover;
  width: auto;
  margin: auto;
`
