import React, { useState } from 'react'
import { requestOneTimePassword, requestPasswordReset } from 'signup'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { LinkButton } from './common/Links'
import styled from '@emotion/styled'
import logo from 'images/logo.png'
import { LogoCardMedia } from './common/LogoCardMedia'
import theme from 'theme'
import { t } from 'i18n'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'

const Form = styled.form`
  padding-top: 1em;
`

const PaddedCardActions = styled(CardActions)`
  padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)};
`

const PaddedCardContent = styled(CardContent)`
  padding: ${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)};
`

enum View {
  ONE_TIME_PASSWORD = 'ONE_TIME_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD'
}


const ForgotPassword = () => {

  const [view, setView] = useState<View>(View.ONE_TIME_PASSWORD)

  const navigate = useNavigate()
  const { handleSubmit, isSubmitting, values, handleChange, errors } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      sendPasswordRequest(values.email)
        .then(() => navigate('/login', {
          replace: true,
          state: { resetPasswordMessage: t('forgot_password.reset_password_link_sent_message') }
        }))
        .catch(() => {
          setSubmitting(false)
          setFieldError('email', 'Invalid')
        })
    },
  })

  const sendPasswordRequest = async (email) => {
    view == View.ONE_TIME_PASSWORD ? requestOneTimePassword(email) : requestPasswordReset(email)
  }

  return (
    <Container fixed maxWidth='sm'>
      <Form onSubmit={handleSubmit}>
        <Card>
          <LogoCardMedia component='img' src={logo} height={196} />
          <PaddedCardContent>
            <RadioGroup
              style={{ flexDirection: 'row', marginBottom: '10px' }}
              aria-label='view'
              name='view'
              onChange={(event) => {
                setView(View[event.target.value])
              }}
            >
              <FormControlLabel
                checked={view === View.ONE_TIME_PASSWORD}
                id='one-time-password'
                value={View.ONE_TIME_PASSWORD}
                control={<Radio />}
                label={t('forgot_password.one_time_password')}
              />
              <FormControlLabel
                checked={view === View.RESET_PASSWORD}
                id='reset-password'
                value={View.RESET_PASSWORD}
                control={<Radio />}
                label={t('forgot_password.reset_password')}
              />
            </RadioGroup>
            <TextField
              value={values.email}
              name='email'
              onChange={handleChange}
              label={t('email')}
              fullWidth
              error={!!errors.email}
            />
          </PaddedCardContent>
          <PaddedCardActions>
            <Button type='submit' variant='outlined' color='primary' disabled={isSubmitting}>
              {t('forgot_password.request')}
            </Button>
            <LinkButton to={{ pathname: '/login' }}>{t('cancel')}</LinkButton>
          </PaddedCardActions>
        </Card>
      </Form>
    </Container>
  )
}

export default ForgotPassword
