import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '../initializers/sentry'
import { RecoilRoot } from 'recoil'

import React from 'react'
import { createRoot } from 'react-dom/client'

import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import theme from 'theme'

import { ThemeProvider } from '@emotion/react'

import Login from '../components/Login'
import ForgotPassword from '../components/ForgotPassword'
import RecoilNexus from 'recoil-nexus'
import ResetPassword from 'components/ResetPassword'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

const App = () => {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='login/forgot' element={<ForgotPassword />} />
      <Route path='users/password/edit' element={<ResetPassword />} />
      <Route path='*' element={<Navigate to='/login' replace />} />
    </Routes>
  )
}

root.render(
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RecoilRoot>
          <RecoilNexus />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RecoilRoot>
      </ThemeProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
)
