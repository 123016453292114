import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { LinkButton } from './common/Links'
import styled from '@emotion/styled'
import logo from 'images/logo.png'
import { LogoCardMedia } from './common/LogoCardMedia'
import theme from 'theme'
import { t } from 'i18n'
import { resetPassword } from 'signup'
import { ErrorText, PaddedBlock } from './common'

const Form = styled.form`
  padding-top: 1em;
`

const PaddedCardActions = styled(CardActions)`
  padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)};
`

const PaddedCardContent = styled(CardContent)`
  padding: ${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)};
`

const ResetPassword = () => {

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("reset_password_token")
  if (token == null) {
    navigate('/login', { replace: true })
  }

  const { handleSubmit, isSubmitting, values, handleChange, errors } = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      resetPassword(values.password, values.passwordConfirmation, token)
        .then(() => navigate('/login', {
          replace: true,
          state: { resetPasswordMessage: t('forgot_password.password_reset_successfully') }
        }))
        .catch((error) => {
          setSubmitting(false)
          if(!!error.response.data.password) {
            setFieldError('password', error.response.data.password)
          } else if (!!error.response.data.password_confirmation) {
            setFieldError('passwordConfirmation', error.response.data.password_confirmation)
          }
        })
    },
  })

  return (
    <Container fixed maxWidth='sm'>
      <Form onSubmit={handleSubmit}>
        <Card>
          <LogoCardMedia component='img' src={logo} height={196} />
          <PaddedCardContent>
            <PaddedBlock>
              <TextField
                  value={values.password}
                  name='password'
                  onChange={handleChange}
                  label={t('password')}
                  fullWidth
                  type='password'
                  error={!!errors.password}
                />
              <ErrorText error={errors.password} />
            </PaddedBlock>
            <PaddedBlock>
              <TextField
                value={values.passwordConfirmation}
                name='passwordConfirmation'
                onChange={handleChange}
                label={t('confirm_password')}
                fullWidth
                type='password'
                error={!!errors.passwordConfirmation}
              />
              <ErrorText error={errors.passwordConfirmation} />
            </PaddedBlock>
          </PaddedCardContent>
          <PaddedCardActions>
            <Button type='submit' variant='outlined' color='primary' disabled={isSubmitting}>
              {t('forgot_password.set_new_password')}
            </Button>
            <LinkButton to={{ pathname: '/login' }}>{t('cancel')}</LinkButton>
          </PaddedCardActions>
        </Card>
      </Form>
    </Container>
  )
}

export default ResetPassword
