import React from 'react'
import { login } from 'auth'
import { useFormik } from 'formik'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { LinkButton } from './common/Links'
import { LogoCardMedia } from './common/LogoCardMedia'
import styled from '@emotion/styled'
import logo from '../images/logo.png'
import theme from 'theme'
import Link from '@mui/material/Link'
import { t } from 'i18n'
import { useLocation } from 'react-router-dom'
import { Alert } from '@mui/material'

const Form = styled.form`
  padding-top: 1em;
`

const PaddedCardActions = styled(CardActions)`
  padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)};
`

const PaddedCardContent = styled(CardContent)`
  padding: ${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)};
`

const Login = () => {
  const location = useLocation()
  const resetPasswordMessage = location.state?.resetPasswordMessage

  const { handleSubmit, isSubmitting, values, handleChange, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      login(values.email, values.password).catch(() => {
        setSubmitting(false)
        setFieldError('email', 'Invalid')
        setFieldError('password', 'Invalid')
      })
    },
  })

  return (
    <Container fixed maxWidth='sm'>
      <Form onSubmit={handleSubmit}>
        <Card>
          <LogoCardMedia component='img' src={logo} height={196} />
          <PaddedCardContent>
            { !!resetPasswordMessage &&
              <Alert severity="warning" icon={false} style={{marginBottom: '15px'}}>{resetPasswordMessage}</Alert>
            }
            <TextField
              value={values.email}
              name='email'
              onChange={handleChange}
              label={t('email')}
              fullWidth
              error={!!errors.email}
            />
            <TextField
              style={{ marginTop: theme.spacing(3) }}
              value={values.password}
              name='password'
              onChange={handleChange}
              label={t('password')}
              type='password'
              fullWidth
              error={!!errors.password}
            />
          </PaddedCardContent>
          <PaddedCardActions>
            <Button type='submit' variant='outlined' color='primary' disabled={isSubmitting}>
              {t('login')}
            </Button>
            <LinkButton to={{ pathname: '/login/forgot' }}>{t('forgot_password.forgot_password')}</LinkButton>
            <div style={{ flexGrow: 1 }}>
              <Link style={{ float: 'right' }} href='/help'>
                {t('docs')}
              </Link>
            </div>
          </PaddedCardActions>
        </Card>
      </Form>
    </Container>
  )
}

export default Login
