import axios from 'axios'

const SIGN_UP_URL = '/sign_up'
const PASSWORD_RESET_URL = '/reset_password'

export function requestOneTimePassword(email: string) {
  return new Promise((resolve, reject) => {
    axios.post(SIGN_UP_URL, { email }).then(resolve).catch(reject)
  })
}

export function requestPasswordReset(email: string) {
  return new Promise((resolve, reject) => {
    axios.post(PASSWORD_RESET_URL, { email }).then(resolve).catch(reject)
  })
}

export function resetPassword(password: string, password_confirmation: string, reset_password_token: string) {
  return new Promise((resolve, reject) => {
    axios.put('/users/password', {
      user: {
        password: password,
        password_confirmation: password_confirmation,
        reset_password_token: reset_password_token
      }
    }).then(resolve).catch(reject)
  })
}
